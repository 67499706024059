import React from "react";
import ReactPlayer from "react-player";

const VP3 = () => {
  return (
    <div className="videoPlayer-2">
      <div className="videoContainer-2">
        <ReactPlayer
          url="https://youtu.be/Ec3hS7L4fFA?si=at-ywuU06_SAMT5A"
          controls={true}
          className="Player-2"
          width="100%"
          height="100%"
        />
      </div>
      <div className="videoContainer-2">
        <ReactPlayer
          url="https://youtube.com/shorts/quIoBmIjMC4?si=DswWzn4o8mh3HHqC"
          controls={true}
          className="Player-2"
          width="100%"
          height="100%"
          muted={true}
          volume={0}
        />
      </div>
    </div>
  );
};

export default VP3;
