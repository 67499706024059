import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import Product from "../Product/Product";
import "./ProductList.css";

const ProductList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categories, setCategories] = useState(["All"]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchProducts = async (page, category) => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/products`, {
        params: {
          page: page,
          pageSize: pageSize,
          category: category !== "All" ? category : undefined,
        },
      });

      const data = response.data;
      // console.log("Fetched data:", data);

      if (Array.isArray(data.products)) {
        const allCategories = data.products.reduce((acc, curr) => {
          if (Array.isArray(curr.categories)) {
            curr.categories.forEach((category) => {
              if (!acc.includes(category.name)) {
                acc.push(category.name);
              }
            });
          }
          return acc;
        }, []);

        setCategories((prevCategories) => {
          const newCategories = ["All", ...allCategories];
          return prevCategories.length === 1 ? newCategories : prevCategories;
        });

        setProducts((prevProducts) => {
          return page === 1
            ? data.products
            : [...prevProducts, ...data.products];
        });

        setTotalPages(data.pagination.pageTotal);
      } else {
        console.error("Error: data.products is not an array");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage, selectedCategory);
  }, [currentPage, selectedCategory]);

  const observer = useRef();
  const lastProductRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting && currentPage < totalPages && !loading) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }, options);

    if (lastProductRef.current) {
      observer.current.observe(lastProductRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [totalPages, products, loading]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    // setProducts([]); // Clear current products when changing category
  };

  return (
    <div>
      <div className="category-tabs">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="product-list">
        {products.length > 0 ? (
          products
            .filter((product) =>
              selectedCategory === "All"
                ? true
                : product.categories?.some(
                    (cat) => cat.name === selectedCategory
                  )
            )
            .map((product, index) => (
              <div
                key={product.id}
                ref={index === products.length - 1 ? lastProductRef : null}
              >
                <Product product={product} />
              </div>
            ))
        ) : (
          <p>No products available</p>
        )}
        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
};

export default ProductList;
