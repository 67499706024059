import React, { useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { staggerContainer, slideIn } from "../../utils/motion";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = React.createRef();

  const videos = ["/video1.mp4", "/video2.mp4"];
  console.log(videos);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (current) => setCurrentIndex(current),
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      variants={staggerContainer}
      viewport={{ once: false, amount: 0.25 }}
    >
      <motion.div className="hero">
        {/* Header section */}
        <motion.div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "14px",
            marginBottom: "14px",
            gap: "15px",
          }}
        >
          <motion.span
            variants={slideIn("left", "tween", 0.5, 1.4)}
            style={{
              color: "#03c04a",
              fontSize: "50px",
              textAlign: "center",
              fontWeight: "500",
            }}
          >
            BetaHome.ng
          </motion.span>
          <motion.span
            variants={slideIn("right", "tween", 0.5, 1.4)}
            style={{
              color: "#c0c003",
              textAlign: "center",
              fontSize: "45px",
              fontWeight: "500",
            }}
          >
            Home Is Where The Heart Goes
          </motion.span>
        </motion.div>

        {/* Download buttons */}
        <motion.div className="download-buttons">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            variants={slideIn("left", "tween", 0.5, 1.4)}
          >
            <FontAwesomeIcon
              icon={faApple}
              size="3x"
              style={{ color: "#c0c003", marginRight: "5px" }}
            />
            <span>Download</span>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            variants={slideIn("right", "tween", 0.5, 1.4)}
          >
            <a href="https://play.google.com/store/apps/details?id=ng.betahome.betahome">
              <FontAwesomeIcon
                icon={faAndroid}
                size="3x"
                style={{ color: "#c0c003", marginRight: "5px" }}
              />
              <span>Download</span>
            </a>
          </motion.button>
        </motion.div>

        {/* Video Slider */}
        <Swiper spaceBetween={50} slidesPerView={1}>
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <motion.video
                autoPlay
                loop
                muted
                src={video}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </motion.div>
    </motion.div>
  );
};

export default Hero;
