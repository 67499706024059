import React from "react";

import AboutUs from "../../Components/AboutUs/AboutUs";
import VideoPlayer from "../../Components/videoPlayer/VideoPlayer";
import MissionVision from "../../Components/MissionVision/MissionVision";
import VP from "../../Components/videoPlayer2/vp";

const About = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <AboutUs />
      <VideoPlayer />
      <VP />
      <MissionVision />
    </div>
  );
};

export default About;
