import React, { useState, useEffect } from "react";
import PackageHeader from "./components/Header/HeaderBlock";
import { motion } from "framer-motion";
import { staggerChildren, slideIn } from "../../utils/motion";
import { useNavigate } from "react-router-dom";
import Axios from "../../constants/api_management/MyHttpHelperWithToken";
import "./Package.css";

const Package = () => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  // Fetch package data from the API
  const fetchPackageData = async () => {
    try {
      const response = await Axios.get("/packages");
      const data = response?.data?.data;
      // console.log("Fetched data:", data);

      // Store the data in local storage with a timestamp
      const timestamp = Date.now();
      localStorage.setItem("packages", JSON.stringify({ data, timestamp }));
      setPackages(data); // Update state with fetched data
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching package data:", error);
      setLoading(false); // Stop loading if there's an error
    }
  };

  // Handle fetching from local storage or backend
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("packages"));
    const twentyMinutes = 20 * 60 * 1000; // 20 minutes in milliseconds

    if (storedData && Date.now() - storedData.timestamp < twentyMinutes) {
      // Use cached data if it's still valid
      setPackages(storedData.data);
      setLoading(false);
    } else {
      // Fetch new data if cached data is stale
      fetchPackageData();
    }
  }, []);

  const navigate = useNavigate();

  // Handle clicking on a package
  const handlePackageClick = (pkg) => {
    window.scrollTo(0, 0);
    navigate(`/package-details/${pkg._id}`, {
      state: { package: pkg },
    });
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="show"
      variants={staggerChildren}
      viewport={{ once: false, amount: 0.25 }}
      style={{
        marginTop: "10px",
        marginBottom: "20px",
      }}
    >
      <motion.h2
        style={{
          fontSize: "25px",
          fontWeight: "700",
          marginBottom: "7px",
        }}
        variants={slideIn("up", "tween", 0.5, 1.4)}
      >
        Packages
      </motion.h2>
      <motion.div className="package-list">
        {loading ? (
          <p>Loading...</p>
        ) : Array.isArray(packages) && packages.length > 0 ? (
          packages.map((pkg) => (
            <motion.div
              key={pkg._id}
              variants={slideIn("right", "tween", 0.5, 1.4)}
            >
              <PackageHeader
                backgroundColor={"#e5de00"} // default color
                packageName={pkg.title}
                firstSpanColor="black"
                secondSpanColor="black"
                onClick={() => handlePackageClick(pkg)}
              />
            </motion.div>
          ))
        ) : (
          <p>No packages available</p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default Package;
