import React, { useState } from "react";
import "./AboutUs.css"; // Import CSS file for styling

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <h2 style={{ color: "#03c04a" }}>Who Are We</h2>
        <p>
          As a Nigerian-based furniture marketing manufacturing company, our
          commitment lies in advocating reforestation, eco-friendly practices,
          and support for local artisans. BetaHome.ng is a service-based
          platform that creates environmentally conscious contemporary furniture
          helping individuals or corporate organizations decorate their homes or
          offices, providing state-of-the-art comfortable homes/offices, a place
          for comfort, rest, and relaxation. We sell new furniture and fittings
          at reasonable prices, Betahome.ng is an environmentally conscious
          marketplace that creates awareness of humans and the ecosystem by
          showcasing furniture made by skilled craftsmen for habitable living
          with earth consciousness in mind, interior decorations, Artwork and
          paintings, as well as facelifts and general rebranding.
        </p>
        {showMore ? (
          <>
            <p>
              Our goal is to provide clients with high-quality, affordably
              priced furniture that adheres to contemporary design standards
              while also having a positive influence on the environment. 90% of
              the raw materials required to meet our client’s daily needs are
              sourced locally, using locally accessible resources like reclaimed
              wood, bamboo, and recycled plastics. This supports local craftsmen
              by giving them a platform to showcase their abilities and promote
              their work. The effectiveness of our efforts is demonstrated by
              our popularity among environmentally conscious consumers in
              Nigeria and elsewhere. Only on special orders or a request from
              clients who want raw materials supplied from outside to meet their
              particular demand, the company has also won praise for its
              contribution to sustainable growth in the furniture sector
              locally.
            </p>
            <button
              className="show-more-mission"
              onClick={() => setShowMore(false)}
            >
              Show Less
            </button>
          </>
        ) : (
          <button
            className="show-more-mission"
            onClick={() => setShowMore(true)}
          >
            Show More
          </button>
        )}
      </div>
      <div className="about-us-image">
        <img src="service1.jpeg" alt="AboutUsImage" />
      </div>
    </div>
  );
};

export default AboutUs;
