import React from "react";
import ReactPlayer from "react-player";
import "./vp.css";

const VP = () => {
  return (
    <div className="videoPlayer-2">
      <div className="videoContainer-2">
        <ReactPlayer
          url="https://youtu.be/tl54Ok4eHfs?si=0dZ_cYyTcvwbOhwX"
          controls={true}
          className="Player-2"
          width="100%"
          height="100%"
        />
      </div>
      <div className="videoContainer-2">
        <ReactPlayer
          url="https://youtube.com/shorts/7pYuf-lY9PA?si=RKPkTQCEjpWzd-Js"
          controls={true}
          className="Player-2"
          width="100%"
          height="100%"
          muted={true}
          volume={0}
        />
      </div>
    </div>
  );
};

export default VP;
