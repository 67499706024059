import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";

const VideoPlayer = () => {
  return (
    <div className="videoPlayer">
      <div className="videoContainer">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=esyptG_0At4&pp=ygUIYmV0YUhvbWU%3D"
          controls={true}
          className="Player"
        />
      </div>

      <div className="content">
        <p className="contentp">
          We are also committed to reforestation efforts because we are aware of
          how deforestation affects Nigeria's natural resources. BetaHome.ng
          greatly reduces its environmental impact by utilizing only products
          made from sustainably sourced resources.
        </p>
        <p className="contentp">
          <span style={{ color: "green" }}>
            <a href="https://vistawoodinternational.com">
              Vistawood International
            </a>
          </span>{" "}
          established the brand called BetaHome.ng to promote and sell furniture
          as a by-product of wood and timber industry.{" "}
          <span style={{ color: "green" }}>
            <a href="https://vistawoodinternational.com">
              Vistawood international
            </a>
          </span>{" "}
          was established with the aim of creating awareness about the effect of
          reforestation how it affects climate change which in turn reduces
          deforestation hereby building a conductive ecosystem for a better
          humanity.
        </p>
      </div>
    </div>
  );
};

export default VideoPlayer;
